<template>
  <section>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <app-select
              v-model="filter.site"
              :options="sites"
              noEmptyValue
              :label="$t('hpTopicOffer.site')"
              id="filter_site"
              disable-form-group
            ></app-select>
          </div>
          <div class="col-lg-3 col-md-6">
            <app-datepicker
              type="date"
              v-model="filter.date"
              id="filter_date"
              :label="$t('hpTopicOffer.date')"
              disable-form-group
            ></app-datepicker>
          </div>
          <div class="col-lg-3 col-md-6">
            <app-select
              v-model="filter.department"
              :options="departments"
              optionTitle="name"
              :label="$t('hpTopicOffer.base')"
              id="filter_department"
              disable-form-group
            ></app-select>
          </div>
          <div class="col-lg-2">
            <button class="btn btn-success pull-right m-t-30" @click="setFilter">
              <i class="fa fa-search"></i> {{ $t('buttons.search') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Select from '../form/select/Select'
import Datepicker from '../form/Datepicker'

export default {
  name: 'HpTopicOfferFilter',
  data () {
    return {
      filter: this.$store.getters['hpTopicOffer/filter']
    }
  },
  components: {
    appSelect: Select,
    appDatepicker: Datepicker
  },
  computed: {
    filterChanged () {
      return JSON.stringify(this.filter)
    },
    departments () {
      return this.$store.getters['department/all']
    },
    sites () {
      return this.$store.getters['site/all'].filter(site => site.enabled)
    }
  },
  methods: {
    setFilter () {
      this.$store.commit('hpTopicOffer/setFilter', this.filter)
      this.$emit('fetch')
    }
  },
  watch: {
    filterChanged () {
      this.setFilter()
    }
  }
}
</script>
