<template>
  <app-module-view :disableBreadcrumb="selectable">
    <template slot="body">
      <app-topicOffer-filter @fetch="fetchList"></app-topicOffer-filter>
      <app-autoloader
        :callback="fetchList"
        :interval="10"
        ref="autoloader"
        :enabled="countdownEnabled"
      ></app-autoloader>
      <div class="top-buttons">
        <div class="d-inline-block"></div>
      </div>
      <div class="card">
        <div class="card-body">
          <div
            class="btn btn-secondary m-b-5 m-r-20"
            :class="{active: selectedSlot === 24}"
            @click="selectSlot(24)"
          >
            {{ $t('hpTopicOffer.all_slots') }}
          </div>
          <div
            v-for="(slot, index) in slots" :key="`slot-${slot}`"
            class="btn btn-secondary m-b-5 m-r-20"
            :class="{
                            active: selectedSlot === slot,
                            [slotStateClass(slotList[index], slot)]: true,
                        }"
            @click="selectSlot(slot)"
          >
            {{ ('0' + slot).substr(-2) }}:00
          </div>
          <table class="table">
            <thead>
            <tr>
              <th v-if="selectable"></th>
              <th>
                <span>{{ $t('article.title') }}</span><br>
                <span class="subtitle">{{ $t('hpTopicOffer.slot_base') }}</span>
                <span class="subtitle">{{ $t('hpTopicOffer.site') }}</span>
                <span class="subtitle">{{ $t('article.filter.rubric') }}</span>
              </th>
              <th>
                <span>{{ $t('hpTopicOffer.pageViews') }}</span>
              </th>
              <th>
                <span>{{ $t('article.order_date') }}</span><br>
                <span>{{ $t('hpTopicOffer.date') }}</span><br>
              </th>
              <th>{{ $t('hpTopicOffer.fb_shared') }}</th>
              <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody v-if="list.length">
            <template v-for="(slotItem, index) in slotList">
              <tr :key="`slotSection_${index}`" v-show="selectedSlot === 24">
                <th v-if="selectable"></th>
                <th colspan="4">SLOT {{ ('0' + slots[index]).substr(-2) }}:00</th>
              </tr>
              <tr
                v-for="item in slotItem.items" :key="item.id"
                v-show="[24, slots[index]].indexOf(selectedSlot) > -1"
                :class="{used: item.used}"
              >
                <td v-if="selectable">
                  <button
                    class="btn btn-default btn-sm"
                    :title="$t('buttons.select')"
                    @click="selectRecord(item.article)"
                  >
                    {{ $t('buttons.select') }}
                  </button>
                </td>
                <td>
                  <span>{{ item.article.field.title }}</span>
                  <br>
                  <span class="subtitle">{{
                      ('0' + item.slot).substr(-2)
                    }}:00 - {{ getUserDepartmentName(item.article.createdBy) }}</span>
                  <span class="subtitle">{{ siteNameById(item.article.site) }}</span>
                  <span class="subtitle">{{ rubricNameById(item.article.mainRubric) }}</span>
                </td>
                <td>
                  <span>{{ item.article.visits }}</span>
                </td>
                <td>
                  <span>{{ item.article.orderDate | prettyDateTime }}</span><br>
                  <span>{{ item.date | prettyDate }}</span>
                </td>
                <td>
                  <app-checkbox
                    v-if="showFbSharedCheckbox"
                    :id="`hp_topic_offer_fb_share_${item.id}`"
                    v-model="item.article.setting.fbShared"
                    @input="toggleFbShared(item)"
                  ></app-checkbox>
                </td>
                <td>
                  <app-button-delete
                    v-if="showDeleteButton"
                    :tooltip="$t('recipe.article_exists')"
                    @deleteRecord="removeHpTopicOffer"
                    :recordId="item.id"
                    data-test="hp_topic_offer_btn_delete"
                    btnClass="btn-sm"
                  ></app-button-delete>
                  <router-link
                    tag="a"
                    target="_blank"
                    class="btn btn-default btn-sm"
                    :to="{ name: 'article_detail', params: { id: item.article.id }}"
                  >
                    <i class="fa fa-info"></i>
                  </router-link>
                  <app-checkbox
                    v-if="showApproveCheckbox"
                    :id="`hp_topic_offer_approved_${item.id}`"
                    v-model="item.approved"
                    @input="toggleApproved(item)"
                    :label="$t('hpTopicOffer.approved')"
                    class="dataTables_info"
                  ></app-checkbox>
                </td>
              </tr>
              <template v-if="selectable === false">
                <tr
                  v-show="[24, slots[index]].indexOf(selectedSlot) > -1"
                  v-for="(warning, idx) in slotItem.warnings"
                  :key="`slot_warn_${index}_${idx}`"
                >
                  <td colspan="5">{{ warning }}</td>
                </tr>
              </template>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import ModuleView from '../ModuleView'
import TopicOfferFilter from './HpTopicOfferFilter'
import Checkbox from '../form/Checkbox'
import antaresApi from '../../api/antares'
import ButtonDelete from '../../components/shared/ButtonDelete'
import { dateTimeDayStart, dateTimeDayEnd, dateTimeNow } from '@/services/DateTimeService'
import PermissionService from '@/services/PermissionService'
import CbsApi from '@/api/contentBlockService'
import Autoloader from '../Autoloader'
import { SLOTS, PLUS1_SLOT_SCHEDULE, PLUS1_SLOT_HOLIDAYS_SCHEDULE } from '@/model/HpTopicOffer'

export default {
  name: 'HpTopicOfferList',
  props: {
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countdownEnabled: false,
      selectedSlot: 24,
      slotWarnings: {},
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.HOMEPAGE_TOPIC_OFFER_PERMISSIONS
    }
  },
  components: {
    appModuleView: ModuleView,
    appTopicOfferFilter: TopicOfferFilter,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appAutoloader: Autoloader
  },
  computed: {
    filter () {
      return this.$store.getters['hpTopicOffer/filter']
    },
    sites () {
      return this.$store.getters['site/all']
    },
    holidays () {
      return this.$store.getters['hpTopicOffer/holidays']
    },
    showDeleteButton () {
      return this.hasPermission(this.requiredPermissions.deleteButton) && !this.selectable
    },
    showApproveCheckbox () {
      return this.hasPermission(this.requiredPermissions.approveCheckbox) && !this.selectable
    },
    showFbSharedCheckbox () {
      return this.hasPermission(this.requiredPermissions.fbSharedCheckbox)
    },
    freeDay () {
      const date = moment(this.filter.date).toDate()
      const holidays = this.holidays[date.getFullYear()]
      return [0, 6].includes(date.getDay()) || (holidays && holidays.includes(date.toJSON()))
    },
    slots () {
      if (this.filter.site === 1) {
        return SLOTS.filter((slot) => {
          if (this.list.find((i) => i.slot === slot)) {
            return true
          }
          if (this.freeDay) {
            return PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot] && (
              this.filter.department === 0 || PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot][this.filter.department]
            )
          }
          return PLUS1_SLOT_SCHEDULE[slot] && (
            this.filter.department === 0 || PLUS1_SLOT_SCHEDULE[slot][this.filter.department]
          )
        })
      }
      return SLOTS
    },
    list () {
      return this.$store.getters['hpTopicOffer/list']
    },
    slotList () {
      const slotList = this.slots.map((slot) => {
        const items = this.$store.getters['hpTopicOffer/list'].filter(
          (hpTopicOffer) => (
            slot === hpTopicOffer.slot &&
            (this.selectable === false || hpTopicOffer.approved) &&
            [0, this.getUserDepartmentId(hpTopicOffer.article.createdBy)].indexOf(this.filter.department) > -1
          )
        )
        const warnings = []
        if (this.filter.site === 1) {
          let departments = this.freeDay ? PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot] : PLUS1_SLOT_SCHEDULE[slot]
          if (departments !== undefined) {
            departments = Object.entries(departments)
            if (this.freeDay && PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot][this.filter.department]) {
              departments = [[this.filter.department, PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot][this.filter.department]]]
            } else if (this.freeDay === false && PLUS1_SLOT_SCHEDULE[slot][this.filter.department]) {
              departments = [[this.filter.department, PLUS1_SLOT_SCHEDULE[slot][this.filter.department]]]
            }
            for (const [departmentId, approvedCount] of departments) {
              const approved = items.filter(
                (hpTopicOffer) =>
                  this.getUserDepartmentId(hpTopicOffer.article.createdBy) === +departmentId &&
                  hpTopicOffer.approved
              )
              if (approvedCount > approved.length) {
                warnings.push(
                  this.$t(
                    'hpTopicOffer.department_articles_missing',
                    { department: this.getDepartmentName(+departmentId) }
                  )
                )
              }
            }
          }
        } else {
          warnings.push(this.$t('hpTopicOffer.articles_missing'))
        }
        return { items, warnings }
      })
      return slotList
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    fetchList () {
      this.countdownEnabled = false
      this.$refs.autoloader.refreshCountdown()
      this.$store.dispatch('hpTopicOffer/fetch')
        .then(() => {
          this.list.forEach((hpTopicOffer) => {
            this.articleVisits(hpTopicOffer.article)
          })
          const documentIds = this.list.map((hpTopicOffer) => hpTopicOffer.article.documentId)
          this.getUsedArticles(documentIds)
          this.countdownEnabled = true
        })
    },
    getSiteTitle (id) {
      const site = this.sites.find((site) => site.id === id)
      if (site === undefined) return id
      return site.title
    },
    getCategoryTitleById (id) {
      const category = this.$store.getters['category/categoryById'](id)
      if (category) return category.title
      return id
    },
    getDepartmentName (id) {
      const department = this.$store.getters['department/getById'](id)
      if (department) {
        return department.name
      }
      return `department(${id})`
    },
    getUserDepartmentId (id) {
      return this.$store.getters['user/userById'](id)?.department
    },
    getUserDepartmentName (id) {
      const departmentId = this.$store.getters['user/userById'](id)?.department
      if (departmentId) {
        const department = this.$store.getters['department/getById'](departmentId)
        if (department) {
          return department.name
        }
        return `department(${departmentId})`
      }
      return `user(${id})`
    },
    toggleApproved (record) {
      this.$store.dispatch('hpTopicOffer/updateApproved', record)
    },
    async toggleFbShared (record) {
      try {
        await this.$store.dispatch('article/updateFbShared', record)
        this.list.filter(({ article }) => article.documentId === record.article.documentId).forEach(({ article }) => {
          article.setting.fbShared = record.article.setting.fbShared
        })
      } catch (error) {
        console.error(error)
      }
    },
    removeHpTopicOffer (id) {
      this.$store.dispatch('hpTopicOffer/deleteRecord', { id })
        .then(() => {
          this.fetchList()
        })
    },
    rubricNameById (id) {
      const rubric = this.$store.getters['rubric/rubricById'](id)
      if (rubric) {
        return rubric.title
      }
      return id
    },
    siteNameById (id) {
      const site = this.$store.getters['site/siteById'](id)
      if (site) {
        return site.name
      }
      return id
    },
    articleVisits (article) {
      if (process.env.NODE_ENV !== 'development') {
        antaresApi().get(`/getVisits?system=eagle&documentId=${article.documentId}&portal=` + this.siteNameById(article.site))
          .then((result) => {
            if (result.data && result.data.length) Vue.set(article, 'visits', result.data[0].count)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    getUsedArticles (documentIds) {
      CbsApi().get(
        '/contentBlocks?limit=1000&filter_in[articleDocumentId]=' + documentIds.join(',') +
        '&filter_gte[publishedSince]=' + dateTimeDayStart(this.filter.date) +
        '&filter_lte[publishedUntil]=' + dateTimeDayEnd(this.filter.date) +
        '&filter_lte[publishedSince]=' + dateTimeNow()
      )
        .then((result) => {
          result.data.data.forEach((contentItem) => {
            this.list
              .filter((hpTopicOffer) => hpTopicOffer.article.documentId === contentItem.articleDocumentId)
              .forEach((hpTopicOffer) => {
                Vue.set(hpTopicOffer, 'used', true)
              })
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    selectSlot (slot) {
      this.selectedSlot = slot
    },
    selectRecord (record) {
      this.$emit('select-record', record)
      this.$emit('close')
    },
    slotStateClass (slotItem, slot) {
      if (slotItem.items.length === 0) {
        return 'empty'
      }
      if (this.filter.site === 1) {
        let departments = this.freeDay ? PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot] : PLUS1_SLOT_SCHEDULE[slot]
        if (departments === undefined) {
          return 'delivered'
        }
        departments = Object.entries(departments)
        let state = 'approved'
        if (slotItem.warnings.length === departments.length) {
          state = 'empty'
        } else if (slotItem.warnings.length > 0) {
          state = 'delivered'
        }
        return state
      }
      if (slotItem.items.filter((item) => item.approved).length === 2) {
        return 'approved'
      }
      if (slotItem.items.length > 0) {
        return 'delivered'
      }
    }
  },
  created () {
    this.$store.dispatch('hpTopicOffer/fetchHolidays')
  },
  mounted () {
    this.fetchList()
  }
}
</script>

<style lang="scss">
.top-buttons {
  position: absolute;
  top: -56px;
  right: 8px;
  z-index: 5;
}

.subtitle {
  margin-right: 15px;
  font-size: 90%;

  td > & {
    text-transform: uppercase;
  }
}

.empty {
  color: #fff;
  background: #fb8888;
}

.delivered {
  color: #fff;
  background: #f3bb87;
}

.approved {
  color: #fff;
  background: #a8de9f;
}

.used {
  background: #efe4b0;
}
</style>
